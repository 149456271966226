import axios from 'axios';
import { uniqBy, countBy } from 'lodash';
import { api } from './config';

export const getProductFromBarcode = async (barcode) => {
  try {
    const response = await axios.get(
      `${api.url}/items/product_variations`,
      {
        params: {
          filter: {
            stock_status: {
              _eq: 'instock',
            },
            code: {
              _eq: barcode,
            },
            products_id: {
              catalog_visibility: {
                _in: ['visible', 'offline'],
              },
            },
          },
          fields: [
            '*',
            'products_id.*',
            'products_id.brands_id.*',
            'products_id.colors_id.*',
            'products_id.images.*',
            'sizes_id.*',
          ],
        },
        headers: { Authorization: `Bearer ${api.authToken}` },
      },
    );
    return response.data.data[0];
  } catch (e) {
    return e;
  }
};

// eslint-disable-next-line
export const createOrder = async ({ cart, paymentMethod, total, discount, storeNotes }) => {
  const uniques = uniqBy(cart, 'id');
  const quantities = countBy(cart, 'id');
  const payments = { creditCard: 2, bancomat: 5, cash: 6 };
  const data = {
    stores_id: 1,
    payments_id: payments[paymentMethod],
    subtotal: total,
    discount,
    total: total - discount,
    store_notes: storeNotes,
    order_items: uniques.map((item) => ({
      product_variations_id: item.id,
      products_price: item.price,
      products_discount: 0,
      products_sale_price: item.price,
      qty: quantities[item.id],
    })),
  };

  await axios.post(`${api.url}/orders`, data);
};
