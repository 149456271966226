import React, { useContext } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CartContext from '../../CartContext';
import { formatNumber } from '../Utils';

const DialogResto = () => {
  const { state, dispatch } = useContext(CartContext);

  return (
    <Dialog open={state.dialogRestoOpen}>
      <DialogContent style={{ textAlign: 'center' }}>
        <CheckCircleOutlineOutlinedIcon style={{ fontSize: '80px' }} />
        <div style={{ fontSize: '18px' }}>
          Resto:
          {` ${formatNumber(state.change)}`}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          dispatch({ type: 'CLOSE_RESTO_DIALOG' });
        }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogResto;
