/* eslint-disable no-shadow */
/* eslint-disable comma-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import itLocale from 'date-fns/locale/it';
import axios from 'axios';
import { api } from '../config';

const paymentOptionsDefault = [
  {
    label: 'Tutti',
    value: 'all'
  }
];

const FiltersVendite = (props) => {
  const locale = itLocale;
  const [query, setQuery] = useState('');
  const [paymentOptions, setPaymentOptions] = useState(paymentOptionsDefault);

  useEffect(() => {
    axios.get(`${api.url}/items/payments`).then((response) => {
      const payments = [
        ...paymentOptionsDefault,
        ...response.data.data.map((payment) => ({ label: payment.name_it, value: payment.id })),
      ];
      setPaymentOptions(payments);
    });
  }, []);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    props.delayedQuery(event.target.value);
  };

  const handlePaymentChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    props.setFilters((prevFilters) => ({
      ...prevFilters,
      paymentMethod: value
    }));
  };

  const handleDateChange = (newDate) => {
    props.setFilters((prevFilters) => ({
      ...prevFilters,
      date: newDate
    }));
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Numero vendita"
            type="text"
            value={query}
            variant="outlined"
            onChange={handleQueryChange}
          />
        </Grid>
        <Grid item lg={3} xs={6}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={locale}
          >
            <DatePicker
              label="Data vendita"
              inputFormat="dd/MM/yyyy"
              value={props.filters.date}
              clearable
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            value={props.filters.paymentMethod || 'all'}
            select
            onChange={handlePaymentChange}
            label="Metodo di pagamento"
          >
            { paymentOptions.map((paymentOption) => (
              <MenuItem
                key={paymentOption.value}
                value={paymentOption.value}
              >
                {paymentOption.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FiltersVendite;
