// import * as Sentry from '@sentry/browser';

function fakeSendMessage(message) {
  return new Promise((resolve) => {
    // eslint-disable-next-line no-console
    console.log(message);
    const response = new Document();
    const content = response.createElement('xml');
    response.appendChild(content);
    content.innerHTML = `<errorCode>0</errorCode>
       <printerError>0</printerError>
       <paperEnd>0</paperEnd>
       <coverOpen>0</coverOpen>
       <busy>0</busy>
      `;
    resolve(response);
  });
}

export default fakeSendMessage;
