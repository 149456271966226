import sendMessage from './printf';
import fakeSendMessage from './printfTest';

// eslint-disable-next-line import/no-mutable-exports
let exported = sendMessage;

if (process.env.REACT_APP_FAKE_POS) {
  exported = fakeSendMessage;
} else {
  exported = sendMessage;
}

export default exported;
