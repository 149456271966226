import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import CartContext from '../../CartContext';

const Sconto = () => {
  const { state, dispatch } = useContext(CartContext);
  const [discountType, setDiscountType] = useState('percentuale');
  const [discount, setDiscount] = useState('');

  return (
    <Box sx={{ flexGrow: 1, mt: 8 }}>
      <Grid container spacing={3}>
        <Grid item md={4} xs={6}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            value={discountType}
            onChange={(event) => {
              setDiscountType(event.target.value);
            }}
            label="Tipologia di sconto"
          >
            <MenuItem value="percentuale">
              Sconto in percentuale
            </MenuItem>
            <MenuItem value="valore">
              Sconto in euro
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Valore"
            type="text"
            variant="outlined"
            value={discount}
            onChange={(event) => { setDiscount(event.target.value); }}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Codice Lotteria Scontrini"
            type="text"
            variant="outlined"
            value={state.lotteryCode}
            onChange={(event) => { dispatch({ type: 'SET_LOTTERY_CODE', payload: event.target.value }); }}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            onClick={() => {
              dispatch({ type: 'SET_DISCOUNT', payload: { discount, discountType } });
            }}
          >
            Applica
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Sconto;
