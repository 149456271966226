/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { React, useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import axios from 'axios';
import { Link as RouterLink, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import { api } from '../config';
import { formatNumber } from './Utils';

const VenditaSingola = () => {
  const [sale, setSale] = useState(null);
  // eslint-disable-next-line prefer-const
  let { venditaId } = useParams();

  const getSingleOrder = async (params) => axios.get(`${api.url}/items/orders/${venditaId}`, { params, headers: { Authorization: `Bearer ${api.authToken}` } });

  useEffect(() => {
    getSingleOrder({
      meta: '*',
      fields: [
        'id',
        'created_at',
        'store_notes',
        'subtotal',
        'discount',
        'total',
        'order_items.products_discount',
        'order_items.products_price',
        'order_items.qty',
        'order_items.product_variations_id.products_id.brands_id.name',
        'order_items.product_variations_id.products_id.colors_id.name_it',
        'order_items.product_variations_id.products_id.code',
        'order_items.product_variations_id.sizes_id.name',
        'order_items.product_variations_id.products_id.images.directus_files_id',
        'payments_id.name_it',
      ],
      sort: '-id',
    }).then((response) => {
      setSale(response.data.data);
    });
  }, []);

  if (!sale) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          mt: 4,
        }}
      >
        <CircularProgress />
      </Grid>
    );
  // eslint-disable-next-line no-else-return
  } else {
    return (
      <div>
        <Grid>
          <AppBar position="static">
            <Toolbar>
              <Typography
                align="left"
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                { `Vendita numero ${sale.id}`}
              </Typography>
              <Button
                startIcon={<ArrowBackRoundedIcon fontSize="small" />}
                variant="contained"
                to="/vendite"
                component={RouterLink}
              >
                Torna alla lista
              </Button>
            </Toolbar>
          </AppBar>
        </Grid>
        <Box sx={{ flexGrow: 1, mt: 5 }}>
          <Grid container justifyContent="flex-end" spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Articolo</TableCell>
                      <TableCell>Taglia</TableCell>
                      <TableCell>Colore</TableCell>
                      <TableCell align="right">Prezzo</TableCell>
                      <TableCell align="right">Qtà</TableCell>
                      <TableCell align="right">Subtotale</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(sale.order_items.length) ? (
                      sale.order_items.map((sale) => {
                        return (
                          <TableRow
                            key={sale.product_variations_id.id}
                          >
                            <TableCell component="th" scope="row">
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              >
                                {sale.product_variations_id.products_id.images[0] ? (
                                  <Box
                                    sx={{
                                      alignItems: 'center',
                                      display: 'flex',
                                      height: 42,
                                      justifyContent: 'center',
                                      overflow: 'hidden',
                                      width: 42,
                                      '& img': {
                                        height: 'auto',
                                        width: '100%',
                                      },
                                    }}
                                  >
                                    <img
                                      alt="Foto"
                                      src={`${api.url}/assets/${sale.product_variations_id.products_id.images[0].directus_files_id}`}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      backgroundColor: '#808080',
                                      alignItems: 'center',
                                      display: 'flex',
                                      height: 42,
                                      justifyContent: 'center',
                                      width: 42,
                                    }}
                                  >
                                    <ImageRoundedIcon fontSize="small" />
                                  </Box>
                                )}
                                <Box>
                                  <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    sx={{ ml: 2 }}
                                  >
                                    {sale.product_variations_id.products_id.brands_id.name}
                                  </Typography>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                    sx={{ ml: 2 }}
                                  >
                                    {sale.product_variations_id.products_id.code}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>{sale.product_variations_id.sizes_id.name}</TableCell>
                            <TableCell>{sale.product_variations_id.products_id.colors_id.name_it}</TableCell>
                            <TableCell align="right">{formatNumber(sale.products_price)}</TableCell>
                            <TableCell align="right">{sale.qty}</TableCell>
                            <TableCell align="right">{formatNumber(sale.products_price * sale.qty)}</TableCell>
                          </TableRow>
                        );
                      }))
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>Subtotale</TableCell>
                      <TableCell align="right">{formatNumber(sale.subtotal)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>Sconto</TableCell>
                      <TableCell align="right">{(sale.discount) ? `-${formatNumber(sale.discount)}` : formatNumber(0)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>TOTALE</TableCell>
                      <TableCell align="right">{formatNumber(sale.total)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 14 }}>
          <Grid container spacing={5}>
            <Grid item md={6} xs={12}>
              <Typography variant="body1">
                Note
              </Typography>
              <Box sx={{ mt: 2 }}>
                {sale.store_notes ? sale.store_notes : (
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Nessuna nota
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="body1">
                Informazioni
              </Typography>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Metodo di pagamento
                      </TableCell>
                      <TableCell align="right">{sale && sale.payments_id && sale.payments_id.name_it}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Data vendita
                      </TableCell>
                      <TableCell align="right">{sale && sale.created_at && `${sale.created_at.slice(8, 10)}/${sale.created_at.slice(5, 7)}/${sale.created_at.slice(0, 4)}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Orario vendita
                      </TableCell>
                      <TableCell align="right">{sale && sale.created_at && sale.created_at.slice(11, 16)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>

      </div>
    );
  }
};
export default VenditaSingola;
