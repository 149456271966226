import React, { useContext } from 'react';
import {
  Box, FormControl, FormControlLabel,
  Grid, Radio, RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Typography,
} from '@material-ui/core';
import CartContext from '../../CartContext';
import { formatNumber } from '../Utils';

const Riepilogo = () => {
  const { state, dispatch } = useContext(CartContext);

  return (
    <Box sx={{ flexGrow: 1, mt: 8 }}>
      <Grid container spacing={5}>
        <Grid item md={6} xs={12}>
          <Typography variant="body1">
            Riepilogo vendita
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Articoli
                  </TableCell>
                  <TableCell align="right">{state.cart.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Subtotale
                  </TableCell>
                  <TableCell align="right">{formatNumber(state.total)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sconto
                  </TableCell>
                  <TableCell align="right">{formatNumber(state.discount)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TOTALE
                  </TableCell>
                  <TableCell align="right">{formatNumber(state.total - state.discount)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="body1">
            Metodo di pagamento
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="paymentMethod"
                  defaultValue="creditCard"
                  name="radio-buttons-group"
                  value={state.paymentMethod}
                  onChange={(e) => {
                    dispatch({ type: 'SET_PAYMENT_METHOD', payload: e.target.value });
                  }}
                >
                  <FormControlLabel
                    value="creditCard"
                    control={<Radio />}
                    label="Carta di Credito"
                  />
                  <FormControlLabel
                    value="bancomat"
                    control={<Radio />}
                    label="Bancomat"
                  />
                  <FormControlLabel
                    value="cash"
                    onClick={() => {
                      dispatch({ type: 'OPEN_CONTANTI_DIALOG' });
                    }}
                    control={<Radio />}
                    label="Contanti"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Note"
                multiline
                rows={4}
                value={state.storeNotes}
                onChange={(event) => {
                  dispatch({ type: 'SET_STORE_NOTES', payload: event.target.value });
                }}
                // defaultValue="Default Value"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Riepilogo;
