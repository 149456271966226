/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
} from '@material-ui/core';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import itLocale from 'date-fns/locale/it';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

const FiltersVendite = (props) => {
  const locale = itLocale;
  const [query, setQuery] = useState('');

  const handleDateChange = (newDate) => {
    props.setFilters((prevFilters) => ({
      ...prevFilters,
      date: newDate,
    }));
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    props.delayedQuery(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Codice SKU o EAN"
            type="text"
            value={query}
            variant="outlined"
            onChange={handleQueryChange}
          />
        </Grid>
        <Grid item lg={3} xs={6}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={locale}
          >
            <DatePicker
              label="Data ordine"
              inputFormat="dd/MM/yyyy"
              value={props.filters.date}
              clearable
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FiltersVendite;
