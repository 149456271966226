import React, { useContext } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CartContext from '../../CartContext';

const DialogError = () => {
  const { state, dispatch } = useContext(CartContext);

  return (
    <Dialog open={state.dialogError}>
      <DialogContent style={{ textAlign: 'center' }}>
        <ErrorOutlineIcon style={{ fontSize: '80px' }} />
        <div style={{ fontSize: '18px' }}>
          Errore
        </div>
        <div style={{ fontSize: '18px' }}>
          { state.error.message }
        </div>
      </DialogContent>
      <DialogActions>

        {state.error.tryAgainButton
        && (
        <Button onClick={() => {
          dispatch({ type: 'CLOSE_ERROR_DIALOG' });
          dispatch({ type: 'CONFIRM_SELL' });
        }}
        >
          RIPROVA
        </Button>
        )}

        <Button onClick={() => {
          dispatch({ type: 'CLOSE_ERROR_DIALOG' });
        }}
        >
          { state.error.closeText }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogError;
