/* eslint-disable arrow-body-style */
import React from 'react';
import {
  TextField,
  Grid,
  Button,
  Box,
} from '@material-ui/core';

const Esci = () => {
  return (
    <div>
      <Box sx={{ flexGrow: 1, mt: 40, mx: 30 }}>
        <Grid>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <TextField
                fullWidth
                id="outlined-search"
                label="Email"
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                id="outlined-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item md={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
              >
                accedi
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Esci;
