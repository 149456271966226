const getPrintfError = (response) => {
  if (!response) return 'La transazione non è andata a buon fine';
  if (response.querySelector('errorCode').innerHTML !== '0') return 'Errore della stampante';
  if (response.querySelector('printerError').innerHTML !== '0') return 'Errore della stampante';
  if (response.querySelector('paperEnd').innerHTML !== '0') return 'La carta è terminata';
  if (response.querySelector('coverOpen').innerHTML !== '0') return 'Coperchio aperto';
  if (response.querySelector('busy').innerHTML !== '0') return 'La transazione non è andata a buon fine';
  return 'noerror';
};

export default getPrintfError;
