/* eslint-disable arrow-body-style */
import React from 'react';
import { Typography } from '@material-ui/core';

const Cassetto = () => {
  return (
    <div>
      <Typography
        color="textPrimary"
        variant="h5"
      >
        Cassetto
      </Typography>

    </div>
  );
};

export default Cassetto;
