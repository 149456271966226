import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Sentry.init({ dsn: 'https://35c0a497710d45d0aabdc3debadc0379@o406505.ingest.sentry.io/5274109' });
Sentry.init({ dsn: 'https://64e5bf2e3d73435fba04e99464078619@o1102610.ingest.sentry.io/6128950' });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
