import * as Sentry from '@sentry/browser';

const endpoint = 'http://192.168.1.201/service.cgi';

function sendMessage(message) {
  return new Promise((resolve, reject) => {
    const oReq = new XMLHttpRequest();
    oReq.open('POST', endpoint, true);
    oReq.setRequestHeader('Content-Type', 'application/xml');
    // eslint-disable-next-line func-names
    oReq.onreadystatechange = function () {
      if (oReq.readyState === 4 && oReq.status === 200) {
        const xmlDoc = oReq.responseXML;

        // inizio codice per tracciamento errori (Sentry)
        let errorMessage = 'PrintF scontrino OK';

        if (xmlDoc.querySelector('errorCode').innerHTML !== '0') errorMessage = `PrintF errorCode ${xmlDoc.querySelector('errorCode').innerHTML}`;
        if (xmlDoc.querySelector('printerError').innerHTML !== '0') errorMessage = `PrintF printerError ${xmlDoc.querySelector('printerError').innerHTML}`;
        if (xmlDoc.querySelector('paperEnd').innerHTML !== '0') errorMessage = `PrintF paperEnd ${xmlDoc.querySelector('paperEnd').innerHTML}`;
        if (xmlDoc.querySelector('coverOpen').innerHTML !== '0') errorMessage = `PrintF coverOpen ${xmlDoc.querySelector('coverOpen').innerHTML}`;
        if (xmlDoc.querySelector('busy').innerHTML !== '0') errorMessage = `PrintF busy ${xmlDoc.querySelector('busy').innerHTML}`;

        Sentry.captureEvent({
          extra: {
            message,
            response: xmlDoc.documentElement.innerHTML,
          },
          message: errorMessage,
        });
        // fine codice per tracciamento errori

        resolve(xmlDoc);
      }
    };
    // eslint-disable-next-line func-names
    oReq.ontimeout = function (e) {
      reject(e);
    };
    oReq.send(message);
  });
}

// const sendMessage = message =>
// sendMessageRaw(`<?xml version="1.0" encoding="UTF-8"?><Service>${message}</Service>`);

export const parseXMLResponse = (XML) => {
  const result = {};
  XML.childNodes.forEach((node) => {
    if (node.tagName) {
      if (node.childNodes.length > 1) {
        result[node.tagName] = parseXMLResponse(node);
      } else {
        result[node.tagName] = node.childNodes[0].textContent;
      }
    }
  });
  return result;
};

/*
const test = `
<cmd>=R1/$2500</cmd>
<cmd>=%-/*2.15</cmd>
`;

const test2 = `
<cmd>=C453</cmd>
`;

window.document.querySelector('#test').addEventListener('click',  function (e) {
  sendMessage(test2).then(response => {
    //console.log(parseXMLResponse(response));
    console.log(response.querySelector('busy').innerHTML);
  });
  // const resBody = await res.text();
  // console.log(resBody)
});
*/

export default sendMessage;
