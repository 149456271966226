/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
} from '@material-ui/core';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import axios from 'axios';
import itLocale from 'date-fns/locale/it';
import LoadingButton from '@material-ui/lab/LoadingButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toDate, format } from 'date-fns-tz';
import _ from 'lodash';
import FiltersOrdini from './FiltersOrdini';
import { api } from '../config';

const OrdiniOnline = () => {
  const [movements, setMovements] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [loading, setLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    date: null,
  });

  const getMovements = async (params) => axios.get(`${api.url}/items/movements`, { params, headers: { Authorization: `Bearer ${api.authToken}` } });

  const getFilters = () => {
    const filter = {
      warehouses_id: {
        _eq: 2,
      },
      qty: {
        _lt: 0,
      },
      orders_id: {
        stores_id: {
          _eq: 3,
        },
      },
    };

    if (filters.date !== null) {
      const startDate = new Date(filters.date);
      const endDate = new Date(filters.date);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      filter.created_at = { _between: [startDate, endDate] };
    }

    if (filters.query) {
      // eslint-disable-next-line no-underscore-dangle
      filter._or = [
        { product_variations_id: { code: { _contains: filters.query } } },
        { product_variations_id: { products_id: { code: { _contains: filters.query } } } },
      ];
    }
    return filter;
  };

  const filterById = async (query) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      query,
    }));
  };

  const delayedQuery = useCallback(_.debounce((q) => filterById(q), 500), []);

  useEffect(() => {
    setLoading(true);
    getMovements({
      filter: getFilters(),
      sort: '-id',
      fields: [
        'id',
        'orders_id',
        'product_variations_id.id',
        'product_variations_id.sizes_id.name',
        'product_variations_id.code',
        'description',
        'created_at',
        'qty',
        'product_variations_id.products_id.name',
        'product_variations_id.products_id.code',
        'product_variations_id.products_id.brands_id.name',
        'product_variations_id.products_id.colors_id.name_it',
        'product_variations_id.products_id.images.directus_files_id',
        'product_variations_id.sizes_id.name',
      ],
      meta: '*',
      limit: 15,
    }).then((response) => {
      setMovements(response.data.data);
      setTotal(response.data.meta.filter_count);
      setLoading(false);
    });
  }, [filters]);

  useEffect(() => {
    if (currentPage > 1) {
      setLoadMore(true);
      getMovements({
        filter: getFilters(),
        page: currentPage,
        sort: '-id',
        fields: [
          'id',
          'orders_id',
          'product_variations_id.id',
          'product_variations_id.sizes_id.name',
          'product_variations_id.code',
          'description',
          'created_at',
          'qty',
          'product_variations_id.products_id.name',
          'product_variations_id.products_id.code',
          'product_variations_id.products_id.brands_id.name',
          'product_variations_id.products_id.colors_id.name_it',
          'product_variations_id.products_id.images.directus_files_id',
          'product_variations_id.sizes_id.name',
        ],
        meta: '*',
        limit: 15,
      }).then((response) => {
        setMovements((movementsArray) => [...movementsArray, ...response.data.data]);
        setTotal(response.data.meta.filter_count);
        setLoadMore(false);
      });
    }
  }, [currentPage]);

  return (
    <div>
      <FiltersOrdini
        filters={filters}
        setFilters={setFilters}
        delayedQuery={delayedQuery}
      />
      <Box sx={{ flexGrow: 1, mt: 0 }}>
        <Grid container justifyContent="flex-end" spacing={3}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Numero</TableCell>
                    <TableCell>Prodotto</TableCell>
                    <TableCell>Taglia</TableCell>
                    <TableCell>Colore</TableCell>
                    <TableCell>Qtà</TableCell>
                    <TableCell>Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(isLoading) ? (
                    <TableRow>
                      <TableCell colSpan={8} style={{ borderBottom: '0' }}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            mt: 4,
                          }}
                        >
                          <CircularProgress />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                    : (movements.length) ? (
                      movements.map((movement) => {
                        return (
                          <TableRow
                            key={movement.id}
                          >
                            <TableCell>{movement.orders_id}</TableCell>
                            <TableCell component="th" scope="row">
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              >
                                {movement.product_variations_id.products_id.images[0] ? (
                                  <Box
                                    sx={{
                                      alignItems: 'center',
                                      display: 'flex',
                                      height: 42,
                                      justifyContent: 'center',
                                      overflow: 'hidden',
                                      width: 42,
                                      '& img': {
                                        height: 'auto',
                                        width: '100%',
                                      },
                                    }}
                                  >
                                    <img
                                      alt="Foto"
                                      src={`${api.url}/assets/${movement.product_variations_id.products_id.images[0].directus_files_id}`}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      backgroundColor: '#808080',
                                      alignItems: 'center',
                                      display: 'flex',
                                      height: 42,
                                      justifyContent: 'center',
                                      width: 42,
                                    }}
                                  >
                                    <ImageRoundedIcon fontSize="small" />
                                  </Box>
                                )}
                                <Box>
                                  <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                    sx={{ ml: 2 }}
                                  >
                                    {movement.product_variations_id.products_id.brands_id ? movement.product_variations_id.products_id.brands_id.name : 'Non presente'}
                                  </Typography>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                    sx={{ ml: 2 }}
                                  >
                                    {movement.product_variations_id.products_id.code}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>{movement.product_variations_id.sizes_id.name}</TableCell>
                            <TableCell>{movement.product_variations_id.products_id.colors_id ? movement.product_variations_id.products_id.colors_id.name_it : 'Non presente'}</TableCell>
                            <TableCell>{Math.abs(movement.qty)}</TableCell>
                            <TableCell>{format(toDate(movement.created_at), 'dd MMM yyyy', { locale: itLocale })}</TableCell>
                          </TableRow>
                        );
                      }))
                      : (
                        <TableRow>
                          <TableCell colSpan="9" align="center">
                            <Box component="span" sx={{ py: 5, display: 'block' }}>
                              Nessun risultato
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>

      { total > 15 && isLoading === false ? (
        <Box sx={{ flexGrow: 1, mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                loading={loading}
                loadingIndicator="Caricamento in corso..."
              >
                Carica altri articoli
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      ) : ''}

    </div>
  );
};

export default OrdiniOnline;
