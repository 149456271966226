/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import {
  useState, React, useEffect, useCallback,
} from 'react';
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  Tooltip,
} from '@material-ui/core';
import axios from 'axios';
import _ from 'lodash';
import LoadingButton from '@material-ui/lab/LoadingButton';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { api } from '../config';
import FiltersArticoli from './FiltersArticoli';

const Articoli = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [loading, setLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    warehouse: null,
    category: null,
    brand: null,
    size: null,
    season: null,
    year: null,
    inStock: null,
  });

  const getProducts = async (params) => axios.get(`${api.url}/inventory`, { params, headers: { Authorization: api.authToken } });

  const getFilters = () => {
    const filter = { };

    if (filters.warehouse !== 'all' && filters.warehouse !== null) {
      filter.warehouse = { _eq: filters.warehouse };
    }

    if (filters.category !== 'all' && filters.category !== null) {
      filter.categories_id = { _eq: filters.category };
    }

    if (filters.query) {
      filter.query = { _contains: filters.query };
    }

    if (filters.brand !== 'all' && filters.brand !== null) {
      filter.brands_id = { _eq: filters.brand };
    }

    if (filters.size !== 'all' && filters.size !== null) {
      filter.sizes_id = { _eq: filters.size };
    }
    if (filters.season !== 'all' && filters.season !== null) {
      filter.seasons_id = { _eq: filters.season };
    }

    if (filters.year !== 'all' && filters.year !== null && filters.year !== '') {
      filter.products_year = { _eq: filters.year };
    }

    if (filters.inStock !== 'all' && filters.inStock !== null) {
      filter.products_stock_status = { _eq: filters.inStock };
    }

    return filter;
  };

  const filterById = async (query) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      query,
    }));
  };

  const delayedQuery = useCallback(_.debounce((q) => filterById(q), 500), []);

  useEffect(() => {
    setLoading(true);
    getProducts({
      filter: getFilters(),
      meta: '*',
      limit: 15,
      sort: '-id',
    }).then((response) => {
      setProducts(response.data.data);
      setTotal(response.data.meta.filter_count);
      setLoading(false);
    });
  }, [filters]);

  useEffect(() => {
    if (currentPage > 1) {
      setLoadMore(true);
      getProducts({
        filter: getFilters(),
        fields: [
          '*',
        ],
        page: currentPage,
        meta: '*',
        limit: 15,
        sort: '-id',
      }).then((response) => {
        setProducts((productsArray) => [...productsArray, ...response.data.data]);
        setTotal(response.data.meta.filter_count);
        setLoadMore(false);
      });
    }
  }, [currentPage]);

  return (
    <div>
      <FiltersArticoli
        delayedQuery={delayedQuery}
        filters={filters}
        setFilters={setFilters}
      />
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Prodotto</TableCell>
                      <TableCell>Descrizione</TableCell>
                      <TableCell>Taglia</TableCell>
                      <TableCell>Colore</TableCell>
                      {/* <TableCell>Prezzo</TableCell> */}
                      <TableCell>
                        Status
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title="Punto vendita"
                          placement="top"
                          sx={{ m: 0 }}
                        >
                          <Typography>
                            P
                          </Typography>
                        </Tooltip>

                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title="Centrale"
                          placement="top"
                          sx={{ m: 0 }}
                        >
                          <Typography>
                            C
                          </Typography>
                        </Tooltip>

                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title="E-commerce"
                          placement="top"
                          sx={{ m: 0 }}
                        >
                          <Typography>
                            E
                          </Typography>
                        </Tooltip>

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(isLoading) ? (
                      <TableRow>
                        <TableCell colSpan={8} style={{ borderBottom: '0' }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              mt: 4,
                            }}
                          >
                            <CircularProgress />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                      : (products.length) ? (
                        products && products.map((item) => {
                          return (
                            <TableRow
                              key={item.id}
                            >
                              <TableCell component="th" scope="row">
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  {item.image ? (
                                    <Box
                                      sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        height: 42,
                                        justifyContent: 'center',
                                        overflow: 'hidden',
                                        width: 42,
                                        '& img': {
                                          height: 'auto',
                                          width: '100%',
                                        },
                                      }}
                                    >
                                      <img
                                        alt="Movimento"
                                        src={`${api.url}/assets/${item.image}`}
                                      />
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{
                                        backgroundColor: '#808080',
                                        alignItems: 'center',
                                        display: 'flex',
                                        height: 42,
                                        justifyContent: 'center',
                                        width: 42,
                                      }}
                                    >
                                      <ImageRoundedIcon fontSize="small" />

                                    </Box>
                                  )}
                                  <Box>
                                    <Typography
                                      color="textPrimary"
                                      variant="subtitle2"
                                      sx={{ ml: 2 }}
                                    >
                                      {item.brands_name}
                                    </Typography>
                                    <Typography
                                      color="textSecondary"
                                      variant="body2"
                                      sx={{ ml: 2 }}
                                    >
                                      {item.products_code}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell>{item.products_name ? item.products_name : 'Non presente'}</TableCell>
                              <TableCell>{item.sizes_name}</TableCell>
                              <TableCell>{item.colors_name}</TableCell>
                              {/* <TableCell>{formatNumber(item.products_price)}</TableCell> */}
                              <TableCell>
                                { item.instock === null || item.instock === '' ? (
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Non inserito
                                  </Typography>
                                ) : item.instock > 0 ? (
                                  <Typography
                                    color="#4caf50"
                                    variant="body2"
                                  >
                                    Disponibile
                                  </Typography>
                                ) : (
                                  <Typography
                                    color="#ef5350"
                                    variant="body2"
                                  >
                                    Esaurito
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell>
                                {item.warehouse2 > 0 ? item.warehouse2
                                  : item.warehouse2 < 0
                                    ? (
                                      <Typography
                                        color="error"
                                        variant="body2"
                                      >
                                        { item.warehouse2}
                                      </Typography>
                                    )
                                    : (
                                      <Typography
                                        color="textSecondary"
                                        variant="body2"
                                      >
                                        0
                                      </Typography>
                                    )}

                              </TableCell>
                              <TableCell>
                                {item.warehouse1 > 0 ? item.warehouse1
                                  : item.warehouse1 < 0
                                    ? (
                                      <Typography
                                        color="error"
                                        variant="body2"
                                      >
                                        { item.warehouse1}
                                      </Typography>
                                    )
                                    : (
                                      <Typography
                                        color="textSecondary"
                                        variant="body2"
                                      >
                                        0
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell>
                                {item.warehouse4 > 0 ? item.warehouse4
                                  : item.warehouse4 < 0
                                    ? (
                                      <Typography
                                        color="error"
                                        variant="body2"
                                      >
                                        { item.warehouse4}
                                      </Typography>
                                    )
                                    : (
                                      <Typography
                                        color="textSecondary"
                                        variant="body2"
                                      >
                                        0
                                      </Typography>
                                    )}
                              </TableCell>
                            </TableRow>
                          );
                        }))
                        : (
                          <TableRow>
                            <TableCell colSpan="9" align="center">
                              <Box component="span" sx={{ py: 5, display: 'block' }}>
                                Nessun risultato
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      { total > 15 && isLoading === false ? (
        <Box sx={{ flexGrow: 1, mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                loading={loading}
                loadingIndicator="Caricamento in corso..."
              >
                Carica altri articoli
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      ) : ''}

    </div>
  );
};

export default Articoli;
