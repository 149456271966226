/* eslint-disable comma-dangle */
/* eslint-disable */
import React, { useReducer, useEffect, useState } from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Sidebar from './components/Sidebar';
import CartContext from './CartContext';
import { initialState, reducer, middleware } from './store';

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const theme = React.useMemo(
    () => createTheme({
      palette: {
        mode: 'dark',
      },
    }),
    [],
  );
  const [ip, setIP] = useState('');
  const getData = async () => {
    const res = await axios.get('https://ipapi.co/json/');
    setIP(res.data.ip);
  };

  useEffect(() => {
    getData();
  }, []);

  if (ip === '') {
    return (
      <CircularProgress color="inherit" />
    );
  }

  if (ip === '2.224.130.249' || ip === '2.224.129.214' || ip === '151.78.53.180') {
    return (
      <ThemeProvider theme={theme}>
        <CartContext.Provider
          value={{
            dispatch: middleware(dispatch, state),
            state
          }}
        >
          <BrowserRouter>
            <div className="App">
              <Sidebar />
            </div>
          </BrowserRouter>
        </CartContext.Provider>
      </ThemeProvider>
    );
  }

  return (
    <>
      <Box m={3}>
        <h1>
          403 Forbidden
        </h1>
        <p>
          In the production environment, a token is required to log in.
        </p>
      </Box>
    </>
  );
};

export default App;
