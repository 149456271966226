import React, { useContext } from 'react';
import {
  Box,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import CartContext from '../../CartContext';
import Barcode from './Barcode';
import { formatNumber } from '../Utils';
import Riepilogo from './Riepilogo';
import Sconto from './Sconto';
import DialogContanti from './DialogContanti';
import DialogResto from './DialogResto';
import DialogConfirm from './DialogConfirm';
import DialogEmptyCart from './DialogEmptyCart';
import DialogNoBarcode from './DialogNoBarcode';
import DialogError from './DialogError';
import { api } from '../../config';
import DialogSuccess from './DialogSuccess';

const Cassa = () => {
  const { dispatch, state } = useContext(CartContext);
  return (
    <div>
      <DialogContanti />
      <DialogResto />
      <DialogConfirm />
      <DialogEmptyCart />
      <DialogNoBarcode />
      <DialogError />
      <DialogSuccess />
      <Barcode />
      <Grid>
        <Box
          sx={{ mt: 4 }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Prodotto</TableCell>
                      <TableCell>Taglia</TableCell>
                      <TableCell>Colore</TableCell>
                      <TableCell align="right">Prezzo</TableCell>
                      <TableCell align="right">Azioni</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.cart.length === 0
                    && (
                      <TableRow>
                        <TableCell colSpan="7" align="center">
                          <Box component="span" sx={{ py: 5, display: 'block' }}>
                            Non ci sono articoli in cassa
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                    {state.cart.map((product) => (
                      <TableRow key={product.uniqueId}>
                        <TableCell component="th" scope="row">
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          >
                            {product.image ? (
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  height: 42,
                                  justifyContent: 'center',
                                  overflow: 'hidden',
                                  width: 42,
                                  '& img': {
                                    height: 'auto',
                                    width: '100%',
                                  },
                                }}
                              >
                                <img
                                  alt="Foto"
                                  src={`${api.url}/assets/${product.image}`}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  backgroundColor: '#808080',
                                  alignItems: 'center',
                                  display: 'flex',
                                  height: 42,
                                  justifyContent: 'center',
                                  width: 42,
                                }}
                              >
                                <ImageRoundedIcon fontSize="small" />
                              </Box>
                            )}
                            <Box>
                              <Typography
                                color="textPrimary"
                                variant="subtitle2"
                                sx={{ ml: 2 }}
                              >
                                {product.brand}
                              </Typography>
                              <Typography
                                color="textSecondary"
                                variant="subtitle2"
                                sx={{ ml: 2 }}
                              >
                                {product.sku}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        {/* <TableCell>{product.brand}</TableCell>
                        <TableCell>{product.sku}</TableCell> */}
                        <TableCell>{product.size}</TableCell>
                        <TableCell>{product.color}</TableCell>
                        <TableCell align="right">{formatNumber(product.price)}</TableCell>
                        <TableCell align="right">
                          <DeleteIcon onClick={() => { dispatch({ type: 'REMOVE_PRODUCT', payload: product.uniqueId }); }} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Sconto />
      <Riepilogo />
      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="secondary"
                onClick={() => { dispatch({ type: 'RESET' }); }}
              >
                Annulla
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                onClick={() => {
                  if (state.cart.length) {
                    dispatch({ type: 'SET_DIALOG_CONFIRM', payload: true });
                  } else {
                    dispatch({ type: 'SET_DIALOG_EMPTY_CART', payload: true });
                  }
                }}
              >
                Conferma vendita
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Cassa;
