/* eslint-disable no-nested-ternary */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import {
  Alert,
  Box,
  Grid,
  Button,
  Modal,
  Stack,
  Snackbar,
  Typography,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TextField,
} from '@material-ui/core';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import UploadIcon from '@material-ui/icons/Upload';
import DownloadIcon from '@material-ui/icons/Download';
import axios from 'axios';
import { formatNumber } from './Utils';
import { api } from '../config';

const style = {
  position: 'absolute',
  minHeight: '50%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Modals = () => {
  const [product, setProduct] = useState(null);
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [open, setOpen] = useState(false);

  const showMessage = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const loadArticles = (scarico = false) => {
    if (product) {
      let updatedDescription = description;
      if (!updatedDescription) {
        updatedDescription = scarico ? 'Scarico manuale Punto Vendita' : 'Carico manuale Punto Vendita';
      }
      axios.post(`${api.url}/items/movements`, {
        'product_variations_id': product.id,
        'description': updatedDescription,
        'qty': scarico ? quantity * -1 : quantity,
        'warehouses_id': 2,
      }, {
        headers: {
          'Authorization': `Bearer ${api.authToken}`,
        },
      }).then(() => {
        handleCloseModal();
        setDescription('');
        setQuantity('');
        setProduct(null);
        showMessage();
      });
    } else {
      handleCloseModal();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} variant="filled">
                Movimentazione effettuata!
              </Alert>
            </Snackbar>
          </Stack>
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={() => {
              handleOpenModal();
            }}
          >
            Movimentazione
          </Button>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        align="center"
                        variant="h5"
                      >
                        Movimentazione magazzino
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Codice EAN"
                      variant="outlined"
                      onKeyPress={async (e) => {
                        if (e.charCode === 13) {
                          e.preventDefault();
                          try {
                            const response = await axios.get(`${api.url}/items/product_variations`,
                              {
                                params: {
                                  filter: { code: { _eq: e.target.value } },
                                  sort: '-id',
                                  fields: [
                                    '*',
                                    'id',
                                    'sizes_id.name',
                                    'products_id.brands_id.name',
                                    'products_id.colors_id.name_it',
                                    'products_id.code',
                                    'products_id.images.*',
                                    'products_id.price',
                                    'products_id.discount',
                                  ],
                                  meta: '*',
                                },
                                headers: { Authorization: `Bearer ${api.authToken}` },
                              });
                            if (response.data.data.length > 0) {
                              const newProduct = { ...response.data.data[0] };
                              setProduct(newProduct);
                              setButtonDisabled(false);
                              e.target.value = '';
                            } else {
                              setProduct([]);
                              setButtonDisabled(true);
                            }
                          } catch (err) {
                            // eslint-disable-next-line no-console
                            console.error(err);
                          }
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Articolo</TableCell>
                            <TableCell>Taglia</TableCell>
                            <TableCell>Colore</TableCell>
                            <TableCell>Prezzo</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(product === null || product === undefined) ? (
                            <TableRow>
                              <TableCell colSpan="4" align="center">
                                <Box component="span" sx={{ py: 5, display: 'block' }}>
                                  Nessun articolo inserito
                                </Box>
                              </TableCell>
                            </TableRow>
                          ) : product.length < 1 ? (
                            <TableRow>
                              <TableCell colSpan="4" align="center">
                                <Box component="span" sx={{ py: 5, display: 'block' }}>
                                  <Typography
                                    color="error"
                                  >
                                    Barcode non presente
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                            : (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Box
                                    sx={{
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                  >
                                    {product.products_id.images.length > 0 ? (
                                      <Box
                                        sx={{
                                          alignItems: 'center',
                                          display: 'flex',
                                          height: 42,
                                          justifyContent: 'center',
                                          overflow: 'hidden',
                                          width: 42,
                                          '& img': {
                                            height: 'auto',
                                            width: '100%',
                                          },
                                        }}
                                      >
                                        <img
                                          alt="Foto"
                                          src={`${api.url}/assets/${product.products_id.images[0].directus_files_id}`}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          backgroundColor: '#808080',
                                          alignItems: 'center',
                                          display: 'flex',
                                          height: 42,
                                          justifyContent: 'center',
                                          width: 42,
                                        }}
                                      >
                                        <ImageRoundedIcon fontSize="small" />

                                      </Box>
                                    )}
                                    <Box>
                                      <Typography
                                        color="textPrimary"
                                        variant="body1"
                                        sx={{ ml: 2 }}
                                      >
                                        {product.products_id.brands_id.name}
                                      </Typography>
                                      <Typography
                                        color="textSecondary"
                                        variant="body2"
                                        sx={{ ml: 2 }}
                                      >
                                        {product.products_id.code}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell>{product.sizes_id.name}</TableCell>
                                <TableCell>{product.products_id.colors_id.name_it}</TableCell>
                                <TableCell>{formatNumber(product.products_id.price)}</TableCell>
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1, mt: 6 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      value={description}
                      label="Causale movimento"
                      multiline
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <TextField
                          fullWidth
                          id="outlined-search"
                          label="Quantità"
                          value={quantity}
                          type="text"
                          onChange={(e) => {
                            setQuantity(e.target.value);
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          size="large"
                          startIcon={<UploadIcon />}
                          disabled={buttonDisabled}
                          onClick={() => {
                            loadArticles();
                          }}
                        >
                          Carico
                        </Button>
                      </Grid>
                      <Grid item md={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          size="large"
                          startIcon={<DownloadIcon />}
                          disabled={buttonDisabled}
                          onClick={() => {
                            loadArticles(true);
                          }}
                        >
                          Scarico
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Modals;
