/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
import { useState, React } from 'react';
import {
  Grid,
  Paper,
  Button,
  TextField,
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Modal,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/core/styles';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Stack from '@material-ui/core/Stack';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// first modal styling
const style = {
  position: 'absolute',
  minHeight: '50%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const style2ndModal = {
  position: 'absolute',
  minHeight: '40%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Content = () => {
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2ndModal, setOpen2ndModal] = useState(false);
  const handleOpen2ndModal = () => setOpen2ndModal(true);
  const handleClose2ndModal = () => setOpen2ndModal(false);

  // come creare un array statico di oggetti
  // eslint-disable-next-line no-unused-vars
  // const rowsExample = [
  //   {
  //     'articolo': 'T-shirt Supreme',
  //     'taglia': 'XS',
  //     'colore': 'Viola',
  //     'subtotale': 12,
  //     'sconto': 0,
  //     'totale': 12,
  //   },
  //   {
  //     'articolo': 'T-shirt Gucci',
  //     'taglia': 'XS',
  //     'colore': 'Viola',
  //     'subtotale': 12,
  //     'sconto': 0,
  //     'totale': 12,
  //   },
  //   {
  //     'articolo': 'T-shirt Vans',
  //     'taglia': 'XS',
  //     'colore': 'Viola',
  //     'subtotale': 12,
  //     'sconto': 0,
  //     'totale': 12,
  //   },

  // ];

  // eslint-disable-next-line require-jsdoc
  function createData(name, calories, fat, carbs, protein) {
    return {
      name, calories, fat, carbs, protein,
    };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

  return (
    <div>
      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Item>xs=12</Item>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Item>Elemento sx</Item>
          </Grid>
          <Grid item md={6} xs={12}>
            <Item>Elemento dx</Item>
          </Grid>
        </Grid>
      </Box>

      {/* grid innestate ESEMPIO DI BASE */}
      {/* crei un grid padre o una box
         così il grid container sa prendere le dimensioni correttamente */}
      <Box sx={{ flexGrow: 1, mt: 3 }}>
        {/* ricordati che sul grid container
      non va mai dato xs,md.. solo lo spacing */}
        <Grid container spacing={3}>
          {/* sul grid item ci puoi dare xs,md */}
          <Grid item xs={12} md={6}>
            <Item>6/12</Item>
          </Grid>
          {/* qui vale sempre la stessa cosa,
          se devi creare un grid item container,
        prima crei un grid item con xs, md..
        poi crei un grid container con lo solo lo spacing */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={4} md={6}>
                <Item>3/6</Item>
              </Grid>
              <Grid item xs={8} md={6}>
                <Item>3/6</Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={3} md={6}>
                <Item>1</Item>
              </Grid>
              <Grid item xs={9} md={6}>
                <Item>2</Item>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={3} md={6}>
                <Item>3</Item>
              </Grid>
              <Grid item xs={9} md={6}>
                <Item>4</Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={6}>
                <Item>uno</Item>
              </Grid>
              <Grid item md={6} xs={6}>
                <Item>dos</Item>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={6}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={6}>
                <Item>tres</Item>
              </Grid>
              <Grid item md={6} xs={6}>
                <Item>cuatro</Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item md={10} xs={6}>
            <Grid container spacing={3}>
              <Grid item md={4} xs={6}>
                <Item>a</Item>
              </Grid>
              <Grid item md={4} xs={6}>
                <Item>b</Item>
              </Grid>
              <Grid item md={4} xs={6}>
                <Item>c</Item>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} xs={6}>
            <Item>d</Item>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item md={10} xs={6}>
            <Grid container spacing={3}>
              <Grid item md={3} xs={6}>
                <Item>e</Item>
              </Grid>
              <Grid item md={3} xs={6}>
                <Item>f</Item>
              </Grid>
              <Grid item md={3} xs={6}>
                <Item>g</Item>
              </Grid>
              <Grid item md={3} xs={6}>
                <Item>h</Item>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} xs={6}>
            <Item>i</Item>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, my: 10 }}>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          {/* grid degli input */}
          <Grid item md={6} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  id="outlined-number"
                  label="Number"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  disabled
                  id="outlined-disabled"
                  label="Disabled"
                  defaultValue="Hello World"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Select"
                  helperText="Please select your currency"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required"
                  label="Required"
                  defaultValue="Hello World"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  error
                  id="outlined-error-helper-text"
                  label="Error"
                  defaultValue="Hello World"
                  helperText="Incorrect entry."
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Multiline"
                  multiline
                  rows={4}
                  defaultValue="Default Value"
                />
              </Grid>
              <Grid item md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Label"
                  />
                  <FormControlLabel
                    disabled
                    control={<Checkbox />}
                    label="Disabled"
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked color="primary" />}
                    label="Label colorata"
                  />
                </FormGroup>
              </Grid>
              <Grid item md={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                    <FormControlLabel
                      value="disabled"
                      disabled
                      control={<Radio />}
                      label="disabled"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Label"
                  />
                  <FormControlLabel
                    disabled
                    control={<Switch />}
                    label="Disabled"
                  />
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    label="label colorata"
                  />
                </FormGroup>
              </Grid>

              <Grid item md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Basic example"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>

          {/* grid dei bottoni */}
          <Grid item md={5} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Button variant="contained" fullWidth>Contained</Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" fullWidth>Outlined</Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" disabled fullWidth>
                  Disabled
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" fullWidth startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" color="success" fullWidth>
                  Success
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" color="error" fullWidth>
                  error
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="text" fullWidth>Text</Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" size="small" fullWidth>
                  Small
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" size="medium" fullWidth>
                  Medium
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" size="large" fullWidth>
                  Large
                </Button>
              </Grid>
              <Grid item xs={4}>
                <IconButton aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <IconButton>
                  <NavigateNextRoundedIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <IconButton>
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Fab color="primary" aria-label="add">
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Box>

      <Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell align="right">Calories</TableCell>
                    <TableCell align="right">Fat</TableCell>
                    <TableCell align="right">carbs</TableCell>
                    <TableCell align="right">Protein</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Eclair
                    </TableCell>
                    <TableCell align="right">21</TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">46</TableCell>
                    <TableCell align="right">22</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Cupcake
                    </TableCell>
                    <TableCell align="right">21</TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">46</TableCell>
                    <TableCell align="right">22</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell align="right">Calories</TableCell>
                    <TableCell align="right">Fat</TableCell>
                    <TableCell align="right">carbs</TableCell>
                    <TableCell align="right">Protein</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Eclair
                    </TableCell>
                    <TableCell align="right">21</TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">46</TableCell>
                    <TableCell align="right">22</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Cupcake
                    </TableCell>
                    <TableCell align="right">21</TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">46</TableCell>
                    <TableCell align="right">22</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid>
        <Box
          sx={{ mt: 4 }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Dessert (100g serving)</TableCell>
                      <TableCell align="right">Calories</TableCell>
                      <TableCell align="right">Fat&nbsp;(g)</TableCell>
                      <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                      <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.calories}</TableCell>
                        <TableCell align="right">{row.fat}</TableCell>
                        <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid>
        <Box
          sx={{ mt: 4 }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Dessert (100g serving)</TableCell>
                      <TableCell align="right">Calories</TableCell>
                      <TableCell align="right">Fat</TableCell>
                      <TableCell align="right">carbs</TableCell>
                      <TableCell align="right">Protein</TableCell>
                      <TableCell align="right">Azioni</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Eclair
                      </TableCell>
                      <TableCell align="right">21</TableCell>
                      <TableCell align="right">1</TableCell>
                      <TableCell align="right">46</TableCell>
                      <TableCell align="right">22</TableCell>
                      <TableCell align="right">
                        <IconButton>
                          <NavigateNextRoundedIcon fontSize="small" />
                        </IconButton>
                        <IconButton>
                          <CardGiftcardRoundedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Cupcake
                      </TableCell>
                      <TableCell align="right">21</TableCell>
                      <TableCell align="right">1</TableCell>
                      <TableCell align="right">46</TableCell>
                      <TableCell align="right">22</TableCell>
                      <TableCell align="right">
                        <IconButton>
                          <NavigateNextRoundedIcon fontSize="small" />
                        </IconButton>
                        <IconButton>
                          <CardGiftcardRoundedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid>
        <Box
          sx={{ mt: 4 }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Dessert (100g serving)</TableCell>
                      <TableCell align="right">Calories</TableCell>
                      <TableCell align="right">Fat</TableCell>
                      <TableCell align="right">carbs</TableCell>
                      <TableCell align="right">Protein</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan="5" align="center">
                        <Box component="span" sx={{ py: 5, display: 'block' }}>
                          Nessun risultato
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Box sx={{ flexGrow: 1, mt: 8 }}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Grid
              justifyContent="flex-start"
              container
              direction="column"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h1" component="h2" gutterBottom>
                  h1. Heading
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2" gutterBottom>
                  h2. Heading
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" gutterBottom>
                  h3. Heading
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  h6. Heading
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" gutterBottom>
                  subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" gutterBottom>
                  body1. Lorem ipsum dolor sit amet, consectetur adipisicing e
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AppBar position="static">
                  <Toolbar>
                    <Typography
                      align="left"
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      Titolo
                    </Typography>
                    <Button
                      startIcon={<ArrowBackRoundedIcon fontSize="small" />}
                      variant="text"
                    >
                      Indietro
                    </Button>
                  </Toolbar>
                </AppBar>
              </Grid>
              <Grid item xs={12}>
                <Alert
                  variant="outlined"
                  action={
                    (
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                      // onClick={() => {
                      //   setOpen(false);
                      // }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    )
                  }
                >
                  This is a success alert — check it out!
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Alert variant="filled" severity="error">
                  This is an error alert — check it out!
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleOpen}
                >
                  Open first modal
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography
                              align="center"
                              variant="body1"
                            >
                              small title
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              align="center"
                              variant="h5"
                            >
                              big title
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box sx={{ flexGrow: 1, mt: 6 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="textfield"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="outlined-select-currency"
                            select
                            label="Select"
                            helperText="Please select your currency"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1, mt: 6 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <TableContainer>
                            <Table
                              sx={{ minWidth: 500 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Dessert (100g serving)</TableCell>
                                  <TableCell align="right">Calories</TableCell>
                                  <TableCell align="right">Fat</TableCell>
                                  <TableCell align="right">carbs</TableCell>
                                  <TableCell align="right">Protein</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    Eclair
                                  </TableCell>
                                  <TableCell align="right">21</TableCell>
                                  <TableCell align="right">1</TableCell>
                                  <TableCell align="right">46</TableCell>
                                  <TableCell align="right">22</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    Cupcake
                                  </TableCell>
                                  <TableCell align="right">21</TableCell>
                                  <TableCell align="right">1</TableCell>
                                  <TableCell align="right">46</TableCell>
                                  <TableCell align="right">22</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1, mt: 6 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            label="Multiline"
                            multiline
                            rows={4}
                            defaultValue="Default Value"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={3}>
                            <Grid item md={12}>
                              <TextField
                                fullWidth
                                id="outlined-search"
                                label="Search field"
                                type="text"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={12}>
                              <Button
                                fullWidth
                                variant="contained"
                              >
                                action
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box sx={{ flexGrow: 1, mt: 9 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Button
                            fullWidth
                            disabled
                            variant="contained"
                          >
                            disabled
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="success"
                          >
                            submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Modal>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleOpen2ndModal}
                >
                  Open second modal
                </Button>
                <Modal
                  open={open2ndModal}
                  onClose={handleClose2ndModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style2ndModal}>
                    <Box sx={{ flexGrow: 1, mt: 3 }}>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Fab size="large" color="primary" aria-label="edit">
                          <EditIcon />
                        </Fab>
                        <Typography
                          align="center"
                          variant="h5"
                        >
                          Nessun valore inserito
                        </Typography>
                        <Typography
                          align="center"
                          variant="body1"
                        >
                          Inserisci un barcode
                        </Typography>

                      </Stack>
                    </Box>
                    <Box sx={{ flexGrow: 1, mt: 6 }}>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Button variant="contained" color="info"> OK</Button>

                      </Stack>
                    </Box>
                  </Box>
                </Modal>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Content;
