// eslint-disable-next-line import/named
import { getProductFromBarcode, createOrder } from './api';
import { uniqueId } from './components/Utils';
import generateReceipt from './printf/generateReceipt';
import sendMessage from './printf';
import getPrintfError from './printf/getPrintfError';

export const init = () => ({
  cart: [],
  total: 0,
  discount: 0,
  lotteryCode: '',
  cash: 0,
  change: 0,
  storeNotes: '',
  paymentMethod: 'creditCard',
  dialogContantiOpen: false,
  dialogRestoOpen: false,
  dialogConfirmOpen: false,
  dialogEmptyCart: false,
  dialogNoBarcode: false,
  dialogError: false,
  dialogSuccess: false,
  errorMessage: '',
  error: {
    message: '',
    tryAgainButton: false,
    closeText: 'OK',
  },
});

export const initialState = init();

export const reducer = (state, action) => {
  const newState = { ...state };
  if (action.type === 'ADD_PRODUCT') {
    newState.cart = [...state.cart, action.payload];
  }
  if (action.type === 'REMOVE_PRODUCT') {
    newState.cart = state.cart.filter((product) => product.uniqueId !== action.payload);
  }
  if (action.type === 'ADD_PRODUCT' || action.type === 'REMOVE_PRODUCT') {
    newState.total = newState.cart.reduce((acc, curr) => acc + curr.price, 0);
  }
  if (action.type === 'CLOSE_CONTANTI_DIALOG') {
    newState.dialogContantiOpen = false;
  }
  if (action.type === 'SET_CASH') {
    newState.dialogRestoOpen = true;
    newState.dialogContantiOpen = false;
    newState.cash = action.payload;
    newState.change = newState.cash - newState.total + newState.discount;
  }
  if (action.type === 'SET_STORE_NOTES') {
    newState.storeNotes = action.payload;
  }
  if (action.type === 'OPEN_CONTANTI_DIALOG') {
    newState.dialogContantiOpen = true;
  }
  if (action.type === 'CLOSE_RESTO_DIALOG') {
    newState.dialogRestoOpen = false;
  }
  if (action.type === 'SET_DIALOG_CONFIRM') {
    newState.dialogConfirmOpen = action.payload;
  }
  if (action.type === 'SET_DIALOG_EMPTY_CART') {
    newState.dialogEmptyCart = action.payload;
  }
  if (action.type === 'SET_BARCODE_EMPTY') {
    newState.dialogNoBarcode = action.payload;
  }
  if (action.type === 'OPEN_ERROR_DIALOG') {
    newState.dialogError = true;
    newState.error = action.payload;
  }
  if (action.type === 'CLOSE_ERROR_DIALOG') {
    newState.dialogError = false;
    newState.error = {
      message: '',
      tryAgainButton: false,
    };
  }
  if (action.type === 'SET_DIALOG_SUCCESS') {
    newState.dialogSuccess = action.payload;
  }
  if (action.type === 'SET_PAYMENT_METHOD') {
    newState.paymentMethod = action.payload;
  }
  if (action.type === 'SET_DISCOUNT') {
    if (action.payload.discountType === 'percentuale') {
      newState.discount = state.total * (action.payload.discount / 100);
    }
    if (action.payload.discountType === 'valore') {
      newState.discount = action.payload.discount;
    }
  }
  if (action.type === 'SET_LOTTERY_CODE') {
    newState.lotteryCode = action.payload;
  }
  if (action.type === 'RESET') {
    return init();
  }
  return newState;
};

// eslint-disable-next-line consistent-return
export const middleware = (dispatch, state) => async (action) => {
  switch (action.type) {
    case 'GET_PRODUCT_FROM_BARCODE':
      // eslint-disable-next-line no-case-declarations
      const productVariation = await getProductFromBarcode(action.payload);
      if (productVariation) {
        dispatch({
          type: 'ADD_PRODUCT',
          payload: {
            image: productVariation.products_id.images.length > 0 ? productVariation.products_id.images[0].directus_files_id : '',
            brand: productVariation.products_id.brands_id.name,
            color: productVariation.products_id.colors_id.name_it,
            id: productVariation.id,
            name: productVariation.products_id.name,
            size: productVariation.sizes_id.name,
            sku: productVariation.products_id.code,
            price: productVariation.products_id.price,
            uniqueId: uniqueId(),
          },
        });
      } else {
        dispatch({ type: 'OPEN_ERROR_DIALOG', payload: { message: 'Prodotto non trovato o non presente in magazzino', tryAgainButton: false, closeText: 'OK' } });
      }
      document.querySelector('input#barcode').focus();
      break;
    case 'CONFIRM_SELL':
      dispatch({ type: 'SET_DIALOG_CONFIRM', payload: false });
      // eslint-disable-next-line no-case-declarations
      const receiptResponse = await sendMessage(generateReceipt(state));
      // eslint-disable-next-line no-case-declarations
      const error = getPrintfError(receiptResponse);
      if (error === 'noerror') {
        await createOrder(state);
        dispatch({ type: 'SET_DIALOG_SUCCESS', payload: true });
      } else {
        dispatch({ type: 'OPEN_ERROR_DIALOG', payload: { message: error, tryAgainButton: true, closeText: 'ANNULLA' } });
        await sendMessage('<?xml version="1.0" encoding="UTF-8"><Service><cmd>=x</cmd></Service>');
      }
      document.querySelector('input#barcode').focus();
      break;

    default:
      return dispatch(action);
  }
};
