import React, {
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Box, Button, Grid, TextField,
} from '@material-ui/core';
import CartContext from '../../CartContext';

const Barcode = () => {
  const [barcode, setBarcode] = useState('');
  const { dispatch } = useContext(CartContext);
  const barcodeInput = useRef(null);

  useEffect(() => {
    barcodeInput.current.querySelector('input').focus();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item lg={8} xs={12}>
          <TextField
            fullWidth
            id="barcode"
            label="Codice EAN"
            type="text"
            variant="outlined"
            value={barcode}
            ref={barcodeInput}
            onChange={(e) => { setBarcode(e.target.value); }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                dispatch({ type: 'GET_PRODUCT_FROM_BARCODE', payload: barcode });
                setBarcode('');
              }
            }}
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                onClick={async () => {
                  if (barcode !== '') {
                    dispatch({ type: 'GET_PRODUCT_FROM_BARCODE', payload: barcode });
                    setBarcode('');
                  } else {
                    dispatch({ type: 'SET_BARCODE_EMPTY', payload: true });
                  }
                }}
              >
                Aggiungi
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="secondary"
                onClick={() => {
                  setBarcode('');
                  barcodeInput.current.querySelector('input').focus();
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Barcode;
