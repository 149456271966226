/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import axios from 'axios';
import Modal from './Modal';
import { api } from '../config';

const warehouseOptionsDefault = [
  {
    label: 'Tutti',
    value: 'all',
  },
];

const categoryOptionsDefault = [
  {
    label: 'Tutte',
    value: 'all',
  },
];

const brandOptionsDefault = [
  {
    label: 'Tutte',
    value: 'all',
  },
];

const sizeOptionsDefault = [
  {
    label: 'Tutte',
    value: 'all',
    id: -1,
  },
];

const seasonOptionsDefault = [
  {
    label: 'Tutte',
    value: 'all',
  },
];

const stockOptionsDefault = [
  {
    label: 'Tutti',
    value: 'all',
  },
  {
    label: 'Disponibile',
    value: 'instock',
  },
  {
    label: 'Esaurito',
    value: 'outofstock',
  },
];

const FiltersArticoli = (props) => {
  const [categoryOptions, setCategoryOptions] = useState(categoryOptionsDefault);
  const [warehouseOptions, setWarehouseOptions] = useState(warehouseOptionsDefault);
  const [brandOptions, setBrandOptions] = useState(brandOptionsDefault);
  const [sizeOptions, setSizeOptions] = useState(sizeOptionsDefault);
  const [seasonOptions, setSeasonOptions] = useState(seasonOptionsDefault);
  const [query, setQuery] = useState('');

  useEffect(() => {
    axios.get(`${api.url}/items/warehouses`, { headers: { Authorization: `Bearer ${api.authToken}` } }).then((response) => {
      const filteredWarehouses = response.data.data.filter((warehouse) => warehouse.status === 'published');
      const warehouses = [
        ...warehouseOptionsDefault,
        ...filteredWarehouses.map((warehouse) => ({ label: warehouse.name, value: warehouse.id })),
      ];
      setWarehouseOptions(warehouses);
    });
  }, []);

  useEffect(() => {
    axios.get(`${api.url}/items/seasons`, { headers: { Authorization: `Bearer ${api.authToken}` } }).then((response) => {
      const seasons = [
        ...seasonOptionsDefault,
        ...response.data.data.map((season) => ({ label: season.name, value: season.id })),
      ];
      setSeasonOptions(seasons);
    });
  }, []);

  useEffect(() => {
    axios.get(`${api.url}/items/categories`).then((response) => {
      const categories = [
        ...categoryOptionsDefault,
        ...response.data.data.map((category) => ({ label: category.name_it, value: category.id })),
      ];
      setCategoryOptions(categories);
    });
  }, []);

  useEffect(() => {
    const params = {
      fields: [
        'id',
        'name',
      ],
      sort: [
        'name',
      ],
    };
    axios.get(`${api.url}/items/brands`, { params }).then((response) => {
      const brands = [
        ...brandOptionsDefault,
        ...response.data.data.map((brand) => ({ label: brand.name, value: brand.id })),
      ];
      setBrandOptions(brands);
    });
  }, []);

  useEffect(() => {
    const params = {
      fields: [
        'id',
        'name_it',
        'sizes.id',
        'sizes.name',
      ],
      filter: {
        deleted_at: {
          _null: true,
        },
      },
      sort: [
        'name_it',
      ],
    };
    axios.get(`${api.url}/items/size_categories`, { params, headers: { Authorization: `Bearer ${api.authToken}` } }).then((response) => {
      const sizes = [
        ...response.data.data.map((sizeCategory) => (
          { name_it: sizeCategory.name_it, sizes: sizeCategory.sizes, id: sizeCategory.id }
        )),
      ];
      setSizeOptions(sizes);
    });
  }, []);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    props.delayedQuery(event.target.value);
  };

  const handleCategoryChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    props.setFilters((prevFilters) => ({
      ...prevFilters,
      category: value,
    }));
  };

  const handleWarehouseChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    props.setFilters((prevFilters) => ({
      ...prevFilters,
      warehouse: value,
    }));
  };

  const handleBrandChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    props.setFilters((prevFilters) => ({
      ...prevFilters,
      brand: value,
    }));
  };

  const handleSizeChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    props.setFilters((prevFilters) => ({
      ...prevFilters,
      size: value,
    }));
  };

  const handleSeasonChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    props.setFilters((prevFilters) => ({
      ...prevFilters,
      season: value,
    }));
  };

  const handleYearChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    props.setFilters((prevFilters) => ({
      ...prevFilters,
      year: value,
    }));
  };

  const handleStockChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    props.setFilters((prevFilters) => ({
      ...prevFilters,
      inStock: value,
    }));
  };
  return (
    <Box sx={{ flexGrow: 1, mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Codice SKU o EAN"
            type="text"
            value={query}
            variant="outlined"
            onChange={handleQueryChange}
          />
        </Grid>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            value={props.filters.warehouse || 'all'}
            label="Magazzino"
            onChange={handleWarehouseChange}
          >
            { warehouseOptions.map((warehouseOption) => (
              <MenuItem
                key={warehouseOption.value}
                value={warehouseOption.value}
              >
                {warehouseOption.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            value={props.filters.brand || 'all'}
            label="Marca"
            onChange={handleBrandChange}
          >
            { brandOptions.map((brandOption) => (
              <MenuItem
                key={brandOption.value}
                value={brandOption.value}
              >
                {brandOption.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            value={props.filters.category || 'all'}
            label="Categoria"
            onChange={handleCategoryChange}
          >
            { categoryOptions.map((categoryOption) => (
              <MenuItem
                key={categoryOption.value}
                value={categoryOption.value}
              >
                {categoryOption.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            label="Taglia"
            name="size"
            onChange={handleSizeChange}
            select
            SelectProps={{ native: true }}
            value={props.filters.size || 'all'}
            variant="outlined"
          >
            {sizeOptionsDefault.map((defaultvalue) => (
              <option
                key={defaultvalue.value}
                value={defaultvalue.value}
              >
                {defaultvalue.label}
              </option>
            ))}
            {sizeOptions.map((sizeCategory) => (
              <optgroup
                label={sizeCategory.name_it}
                key={sizeCategory.id}
              >
                { sizeCategory.sizes ? sizeCategory.sizes.map((size) => (
                  <option
                    key={size.id}
                    value={size.id}
                  >
                    {size.name}
                  </option>
                )) : ''}
              </optgroup>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            label="Stagione"
            name="season"
            onChange={handleSeasonChange}
            select
            SelectProps={{ native: true }}
            value={props.filters.season || 'all'}
            variant="outlined"
          >
            {seasonOptions.map((seasonOption) => (
              <option
                key={seasonOption.value}
                value={seasonOption.value}
              >
                {seasonOption.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            label="Anno"
            name="year"
            onChange={handleYearChange}
            value={props.filters.year}
            variant="outlined"
          />
        </Grid>
        <Grid item lg={3} xs={6}>
          <TextField
            fullWidth
            label="Status"
            name="inStock"
            onChange={handleStockChange}
            select
            SelectProps={{ native: true }}
            value={props.filters.inStock || 'all'}
            variant="outlined"
          >
            {stockOptionsDefault.map((stockOption) => (
              <option
                key={stockOption.value}
                value={stockOption.value}
              >
                {stockOption.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Modal />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FiltersArticoli;
