/* eslint-disable no-shadow */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable quotes */
/* eslint-disable arrow-body-style */
import {
  React, useState, useEffect, useCallback
} from 'react';
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
} from '@material-ui/core';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import itLocale from 'date-fns/locale/it';
import { toDate, format } from 'date-fns-tz';
import _ from 'lodash';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { api } from '../config';
import { formatNumber } from './Utils';
import FiltersVendite from "./FiltersVendite";
import sendMessage from "../printf";

const Vendite = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [loading, setLoadMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    date: null,
    paymentMethod: null,
  });

  const getOrders = async (params) => axios.get(`${api.url}/items/orders`, { params, headers: { Authorization: `Bearer ${api.authToken}` } });

  const getFilters = () => {
    const filter = { };
    filter.stores_id = { _eq: 1 };

    if (filters.date !== null) {
      const startDate = new Date(filters.date);
      const endDate = new Date(filters.date);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      filter.created_at = { _between: [startDate, endDate] };
    }

    if (filters.paymentMethod !== 'all' && filters.paymentMethod !== null) {
      filter.payments_id = { _eq: filters.paymentMethod };
    }

    if (filters.query) {
      filter.id = { _contains: filters.query };
    }

    return filter;
  };

  const filterById = async (query) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      query
    }));
  };

  const delayedQuery = useCallback(_.debounce((q) => filterById(q), 500), []);

  useEffect(() => {
    setLoading(true);
    getOrders({
      filter: getFilters(),
      fields: [
        'id',
        'subtotal',
        'discount',
        'total',
        'created_at',
        'payments_id.name_it',
        'order_items.id',
      ],
      meta: '*',
      limit: 15,
      sort: '-id',
    }).then((response) => {
      setOrders(response.data.data);
      setTotal(response.data.meta.filter_count);
      setLoading(false);
    });
  }, [filters]);

  useEffect(() => {
    if (currentPage > 1) {
      setLoadMore(true);
      getOrders({
        filter: getFilters(),
        page: currentPage,
        fields: [
          'id',
          'subtotal',
          'discount',
          'total',
          'created_at',
          'payments_id.name_it',
          'order_items.id',
        ],
        meta: '*',
        limit: 15,
        sort: '-id',
      }).then((response) => {
        setOrders((ordersArray) => [...ordersArray, ...response.data.data]);
        setTotal(response.data.meta.filter_count);
        setLoadMore(false);
      });
    }
  }, [currentPage]);

  return (
    <div>
      <FiltersVendite
        filters={filters}
        setFilters={setFilters}
        delayedQuery={delayedQuery}
      />
      <Box sx={{ flexGrow: 1, mt: 5 }}>
        <Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Numero</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Ora</TableCell>
                      <TableCell>Pagamento</TableCell>
                      <TableCell align="right">Articoli</TableCell>
                      <TableCell align="right">Subtotale</TableCell>
                      <TableCell align="right">Sconto</TableCell>
                      <TableCell align="right">Totale</TableCell>
                      <TableCell align="right">Azioni</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(isLoading) ? (
                      <TableRow>
                        <TableCell colSpan={8} style={{ borderBottom: '0' }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              mt: 4,
                            }}
                          >
                            <CircularProgress />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                      : (orders.length) ? (
                        orders.map((order) => {
                          return (
                            <TableRow
                              key={order.id}
                            >
                              <TableCell component="th" scope="row">{order.id}</TableCell>
                              <TableCell>{format(toDate(order.created_at), 'dd MMM yyyy', { locale: itLocale })}</TableCell>
                              <TableCell>{order.created_at.slice(11, 16)}</TableCell>
                              <TableCell>{order.payments_id.name_it}</TableCell>
                              <TableCell align="right">{order.order_items.length}</TableCell>
                              <TableCell align="right">{formatNumber(order.subtotal)}</TableCell>
                              <TableCell align="right">{formatNumber(order.discount)}</TableCell>
                              <TableCell align="right">{formatNumber(order.total)}</TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => {
                                  sendMessage(`<?xml version="1.0" encoding="UTF-8"><Service>
                            <cmd>=o</cmd>
                            <cmd>="/(            SCONTRINO  DI  CORTESIA)</cmd>
                            <cmd>="/$8/(${order.id})</cmd>
                            <cmd>=o</cmd>
                          </Service>`);
                                }}
                                >
                                  <CardGiftcardRoundedIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  component={RouterLink}
                                  to={`/vendite/${order.id}`}
                                >
                                  <NavigateNextRoundedIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }))
                        : (
                          <TableRow>
                            <TableCell colSpan="9" align="center">
                              <Box component="span" sx={{ py: 5, display: 'block' }}>
                                Nessuna vendita trovata
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      { total > 15 && isLoading === false ? (
        <Box sx={{ flexGrow: 1, mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                loading={loading}
                loadingIndicator="Caricamento in corso..."
              >
                Carica altre vendite
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      ) : ''}
    </div>
  );
};
export default Vendite;
