import React, { useContext } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CartContext from '../../CartContext';

const DialogSuccess = () => {
  const { state, dispatch } = useContext(CartContext);

  return (
    <Dialog open={state.dialogSuccess}>
      <DialogContent style={{ textAlign: 'center' }}>
        <ErrorOutlineIcon style={{ fontSize: '80px' }} />
        <div style={{ fontSize: '18px' }}>
          Ottimo lavoro!
        </div>
        <div style={{ fontSize: '18px' }}>
          La transazione è andata a buon fine
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          dispatch({ type: 'RESET' });
        }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSuccess;
