/* eslint-disable react/no-children-prop */
import { useState, React } from 'react';
import {
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Box,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme, styled } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch, Link as RouterLink } from 'react-router-dom';
import MuiAppBar from '@material-ui/core/AppBar';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import StoreIcon from '@material-ui/icons/Store';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import PeopleIcon from '@material-ui/icons/People';
import Cassa from './Cassa/Cassa';
import Vendite from './Vendite';
import Content from './Content';
import Articoli from './Articoli';
import Esci from './Esci';
import Cassetto from './Cassetto';
import OrdiniOnline from './OrdiniOnline';
import VenditaSingola from './VenditaSingola';
import sendMessage from '../printf';
import CustomersView from './CustomersView';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Sidebar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          // eslint-disable-next-line quote-props
          width: drawerWidth,
          // eslint-disable-next-line quote-props
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            POS
            {theme.direction === 'ltr'
              ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link
            color="textPrimary"
            underline="none"
            component={RouterLink}
            to="/"
          >
            <ListItem button key="Cassa">
              <ListItemIcon>
                <ReceiptOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Cassa" />
            </ListItem>
          </Link>
          <Link
            color="textPrimary"
            underline="none"
            component={RouterLink}
            to="/vendite"
          >
            <ListItem button key="Vendite">
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Vendite" />
            </ListItem>
          </Link>
          {/* <Link
            color="textPrimary"
            underline="none"
            component={RouterLink}
            to="/content"
          >
            <ListItem button key="Componente prova">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Componente prova" />
            </ListItem>
          </Link> */}
          <Link
            color="textPrimary"
            underline="none"
            component={RouterLink}
            to="/articoli"
          >
            <ListItem button key="Inventario">
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Inventario" />
            </ListItem>
          </Link>
          <ListItem
            button
            key="Cassetto"
            onClick={() => {
              sendMessage('<?xml version="1.0" encoding="UTF-8"><Service><cmd>=C86</cmd></Service>');
            }}
          >
            <ListItemIcon>
              <TouchAppIcon />
            </ListItemIcon>
            <ListItemText primary="Cassetto" />
          </ListItem>
          <Link
            color="textPrimary"
            underline="none"
            component={RouterLink}
            to="/ordini"
          >
            <ListItem button key="Ordini Online">
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Ordini Online" />
            </ListItem>
          </Link>
          {/* <Link
            color="textPrimary"
            underline="none"
            component={RouterLink}
            to="/esci"
          >
            <ListItem button key="Esci">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Esci" />
            </ListItem>
          </Link> */}
          <Link
            color="textPrimary"
            underline="none"
            component={RouterLink}
            to="/customers"
          >
            <ListItem button key="Clienti">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Clienti" />
            </ListItem>
          </Link>
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Switch>
          <Route path="/" exact component={Cassa} />
          <Route path="/vendite" exact component={Vendite} />
          <Route path="/content" component={Content} />
          <Route path="/articoli" component={Articoli} />
          <Route path="/ordini" component={OrdiniOnline} />
          <Route path="/cassetto" component={Cassetto} />
          <Route path="/esci" component={Esci} />
          <Route path="/vendite/:venditaId" component={VenditaSingola} />
          <Route path="/customers" component={CustomersView} />
        </Switch>
      </Main>
    </Box>
  );
};

export default Sidebar;
