import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Papa from 'papaparse';
import { api } from '../config';

const CustomersView = () => {
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCustomers = async () => {
      const limit = 10; // Numero di clienti per pagina
      const response = await axios.get(`${api.url}/items/customers?access_token=${api.authToken}&limit=${limit}&page=${currentPage}&meta=*&sort=-date_created&search=${searchTerm}`);
      setCustomers(response.data.data);
      if (response.data.meta && response.data.meta.total_count) {
        const count = response.data.meta.total_count;
        setTotalPages(Math.ceil(count / limit));
      } else {
        // console.error('Unexpected API response format');
      }
    };
    fetchCustomers();
  }, [currentPage, searchTerm]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleExportCSV = async () => {
    const response = await axios.get(`${api.url}/items/customers?limit=-1&sort=date_created`, {
      headers: {
        Authorization: `Bearer ${api.authToken}`,
      },
    });

    if (response.data && response.data.data) {
      const allCustomers = response.data.data;
      const mappedCustomers = allCustomers.map((customer) => ({
        Nome: customer.first_name,
        Cognome: customer.last_name,
        Email: customer.email,
        Telefono: customer.phone,
        Città: customer.city,
        Provincia: customer.province,
        Marketing: customer.privacy_consent,
        Privacy: customer.marketing_consent,
      }));
      const csv = Papa.unparse(mappedCustomers);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const date = new Date();
      const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Clienti_${formattedDate}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: 20 }}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            label="Cerca"
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleExportCSV}
            size="large"
            startIcon={<GetAppIcon />}
          >
            Esporta CSV
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Cognome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Telefono</TableCell>
              <TableCell>Città</TableCell>
              <TableCell>Provincia</TableCell>
              <TableCell>Data Creazione</TableCell>
              {/* <TableCell>Consenso Marketing</TableCell>
              <TableCell>Consenso Privacy</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>{customer.first_name}</TableCell>
                <TableCell>{customer.last_name}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.phone}</TableCell>
                <TableCell>{customer.city}</TableCell>
                <TableCell>{customer.province}</TableCell>
                <TableCell>{new Date(customer.date_created).toLocaleDateString()}</TableCell>
                {/* <TableCell>{customer.marketing_consent ? 'Sì' : 'No'}</TableCell>
                <TableCell>{customer.privacy_consent ? 'Sì' : 'No'}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={totalPages * 10}
                rowsPerPage={10}
                page={currentPage - 1}
                onPageChange={handlePageChange}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomersView;
