import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CartContext from '../../CartContext';
import { formatNumber } from '../Utils';

const DialogContanti = () => {
  const { state, dispatch } = useContext(CartContext);
  const [cash, setCash] = useState('');

  return (
    <Dialog open={state.dialogContantiOpen}>
      <DialogContent style={{ textAlign: 'center' }}>
        <HelpOutlineIcon style={{ fontSize: '80px' }} />
        <div style={{ fontSize: '18px' }}>Contanti</div>
        <div style={{ fontSize: '18px' }}>
          totale:
          {` ${formatNumber(state.total - state.discount)}`}
        </div>
        <br />
        <TextField value={cash} onChange={(e) => { setCash(e.target.value); }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          dispatch({ type: 'SET_CASH', payload: cash });
        }}
        >
          Calcola il resto
        </Button>
        <Button onClick={() => { dispatch({ type: 'CLOSE_CONTANTI_DIALOG' }); }}>Annulla</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogContanti;
