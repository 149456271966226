const generateReceiptPayment = (serverPaymentType, cash, globalTotal) => {
  const money = cash ? cash * 100 : globalTotal * 100;
  switch (serverPaymentType) {
    case 'cash': return `T1/$${money}`;
    default: return 'T4';
  }
};

const removeUnsupportedCharacters = (string) => string.replace(/\(/g, '-').replace(/\)/g, '-').replace(/\//g, '-');

const generateReceipt = (state) => {
  const receiptPayment = generateReceiptPayment(state.paymentMethod, state.cash, state.total);

  const productLines = state.cart.map((item) => `  <cmd>=R1/$${item.price * 100}/(${removeUnsupportedCharacters(item.name)})</cmd>${'\n'}`);

  const ReceiptDiscount = `  <cmd>=S</cmd>${'\n'}  <cmd>=V-/*${Math.round(state.discount * 100)}</cmd>`;

  let lotteryCommand = '';
  if (state.lotteryCode.trim()) {
    lotteryCommand = `${'\n'}  <cmd>=&quot;/?L/$1/(${state.lotteryCode.trim()})</cmd>`;
  }

  return `<?xml version="1.0" encoding="UTF-8">
<Service>
${productLines.join('')}${(state.discount > 0) ? ReceiptDiscount : ''}${lotteryCommand}
  <cmd>=${receiptPayment}</cmd>
  <cmd>=C86</cmd>
</Service>`;
};

export default generateReceipt;
